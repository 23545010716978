import React from 'react';

// Utils
import {createComponent} from '../utils';
import {usePreHeaderBgSx, useSubFooterBgSx} from '../hooks';

// Components
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {Base} from '../../ui/layouts';
import {Box, FlexCol, Heading, Text} from '../../ui/components';
import {Footer, Head, Header, PreHeader, SubFooter} from '../components';
import {PageContextProvider} from '../contexts';

const components = {
    h1: createComponent('h1', Heading, {as: 'h1', level: 2, align: 'center', color: 'textOnDark'}),
    h2: createComponent('h2', Heading, {as: 'h2', level: 3, align: 'center', color: 'textOnDark'}),
    h3: createComponent('h3', Heading, {as: 'h3', level: 4, align: 'center', color: 'textOnDark'}),
    p: createComponent('p', Text, {color: 'textOnDark'}),
    em: createComponent('em', Box, {as: 'em', sx: {fontStyle: 'italic'}}),
    strong: createComponent('strong', Box, {as: 'strong', sx: {fontWeight: 'bold'}}),
    img: (props) => {
        return null;
    }
};

const MdxPage = (props) => {
    const {page} = props.pageContext;

    const preHeaderBgSx = usePreHeaderBgSx();
    const subFooterBgSx = useSubFooterBgSx();

    return (
        <PageContextProvider value={props.pageContext}>
            <Head
                meta={{
                    canonical: page.page.pathname,
                    ...page.meta
                }}
            />
            <Base.Container>
                <Base.PreHeader sx={preHeaderBgSx}>
                    <PreHeader/>
                </Base.PreHeader>
                <Base.Header>
                    <Header/>
                </Base.Header>
                <Base.Body
                    px={['sm', 'md']}
                    py={['md', 'md', 'lg']}
                >
                    <FlexCol
                        as="article"
                        width="100%"
                        maxWidth="3xl"
                        alignX="center"
                        alignY="top"
                        space={['md', 'md', 'lg']}
                    >
                        <MDXProvider components={components}>
                            <MDXRenderer>
                                {page.body}
                            </MDXRenderer>
                        </MDXProvider>
                    </FlexCol>
                </Base.Body>
                <Base.Footer>
                    <Footer/>
                </Base.Footer>
                <Base.SubFooter sx={subFooterBgSx}>
                    <SubFooter/>
                </Base.SubFooter>
            </Base.Container>
        </PageContextProvider>
    );
};

export default MdxPage;
